import React, { useState } from 'react';
import chevronRightSmall from '@ingka/ssr-icon/paths/chevron-right-small';
import chevronLeftSmall from '@ingka/ssr-icon/paths/chevron-left-small';
import SSRIcon from '@ingka/ssr-icon';

const Pagination = (props: any) => {
  const { items, currentPage, handlePageChange, totalPages } = props;
  const ITEMS_PER_PAGE = 7;
  const PAGE_GROUP_SIZE = 5;

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.floor((currentPage - 1) / PAGE_GROUP_SIZE) * PAGE_GROUP_SIZE + 1;
    const endPage = Math.min(startPage + PAGE_GROUP_SIZE, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          style={{ color: currentPage === i ? 'black' : 'grey' }}
          className="pagination-number"
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <span key="end-dots" className="pagination-dots">
          ...
        </span>
      );
    }
    if (endPage < totalPages) {
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          style={{ color: currentPage === totalPages ? 'black' : 'grey' }}
          className="pagination-number"
        >
          {totalPages}
        </button>
      );
    }
    return pageNumbers;
  };

  const handlePageChangeWrapper = (newPage) => {
    if (newPage % PAGE_GROUP_SIZE === 0 && newPage !== totalPages) {
      handlePageChange(newPage);
    } else {
      handlePageChange(newPage);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
      <div>
        Showing {(currentPage - 1) * ITEMS_PER_PAGE + 1}-
        {Math.min(currentPage * ITEMS_PER_PAGE, items?.length)} of {items?.length}
      </div>
      <div style={{}}>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          <SSRIcon paths={chevronLeftSmall} /> Previous
        </button>

        {renderPageNumbers()}
        <button
          onClick={() => handlePageChangeWrapper(currentPage + 1)}
          disabled={currentPage * ITEMS_PER_PAGE >= items?.length}
          className="pagination-button"
        >
          Next <SSRIcon paths={chevronRightSmall} />
        </button>
      </div>
    </div>
  );
};
export default Pagination;
