import React, { useState, useEffect } from 'react';
import Button from '@ingka/button';
import { Select, Option } from '@ingka/select';
import calender from '@ingka/ssr-icon/paths/calendar';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import { manualValidate } from 'Action/manualValidateAction';
import { masterDataManual } from 'Action/manualDropDownAction';
import { MANUAL_SUPPLIER_VALIDATE } from 'Action/types';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import Accordion, { AccordionItem } from '@ingka/accordion';
import TextArea from '@ingka/text-area';
import DatePickerComponent from 'pages/DatePickerComponent/datePickerComponent';
import './supplierDetails.scss';

const SupplierDetails = (props: any) => {
  const [supplierRows, setSupplierRows] = useState([
    {
      supplierId: '',
      ddsReferenceNumber: '',
      ddsVerificationNumber: '',
      ddsActivityType: '',
      ddsFilingDate: '',
    },
  ]);
  const [countryActivity, setCountryActivity] = useState([
    { countryOfActivity: '', borderCrossCountry: '' },
  ]);
  const [operatorRows, setOperatorRows] = useState([
    {
      operatorIdentifier: '',
      operatorValue: '',
      operatorName: '',
      operatorCountry: '',
      operatoraddress: '',
    },
  ]);
  const [selectedCountryActivity, setselectedCountryActivity] = useState<string>('');
  const [shouldValidate, setShouldValidate] = useState(false);
  const [validateVerification, setValidateVerification] = useState(false);
  const [selectedActivityType, setSelectedActivityType] = useState('');
  const [validationMessageColor, setValidationMessageColor] = useState('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedSupplier, setselectedSupplier] = useState<string>('');
  const [isValidate, setIsValidate] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const handleActivityTypeChange = (event) => {
    setSelectedActivityType(event.target.value);
  };
  const dispatch = useDispatch<AppDispatch>();
  const { manualEntryData } = useSelector((state: RootState) => state.manualEntrySubmitData);
  const { error, errorMessage, errorType } = useSelector((state: RootState) => state.error);
  const { masterData } = useSelector((state: RootState) => state.dropDownMasterData);

  const handleSupplierChange = (index: number, field: any, value: any, event: any) => {
    const newSupplierRows = supplierRows.map((row: any, i: any) =>
      i === index ? { ...row, [field]: value } : row
    );
    if (field === 'ddsReferenceNumber' && value.length > 14) {
      setShouldValidate(true);
    } else {
      setShouldValidate(false);
    }
    if (field === 'ddsVerificationNumber' && value.length > 14) {
      setValidateVerification(true);
    } else {
      setValidateVerification(false);
    }
    setSupplierRows(newSupplierRows);
    setselectedSupplier(event.target.value);
  };

  const handleCountryOfActivity = (index: number, field: any, value: any, event: any | null) => {
    const newCountryActivity = countryActivity.map((row: any, i: any) =>
      i === index ? { ...row, [field]: value } : row
    );
    const countryOfActivity = newCountryActivity[index].countryOfActivity;
    const borderCrossCountry = newCountryActivity[index].borderCrossCountry;
    setCountryActivity(newCountryActivity);
  };
  const handleOperator = (index: number, field: any, value: any, event: any | null) => {
    const newOperator = operatorRows.map((row: any, i: any) =>
      i === index ? { ...row, [field]: value } : row
    );
    setOperatorRows(newOperator);
  };

  const handleReset = () => {
    window.location.reload();
  };

  useEffect(() => {
    dispatch(masterDataManual());
  }, []);
  const payload = {
    ddsReferenceNumber: supplierRows[0].ddsReferenceNumber,
    ddsVerificationNumber: supplierRows[0].ddsVerificationNumber,
  };

  const handleValidate = async () => {
    try {
      const response = await dispatch(manualValidate(payload, MANUAL_SUPPLIER_VALIDATE));
      if (response && response?.status === 200) {
        setIsValidate(true);
        setValidationMessage('Validated Successfully');
        setValidationMessageColor('green');
      }
    } catch (error) {
      setValidationMessage('Not Valid DDS');
      setValidationMessageColor('red');
    }
  };

  return (
    <div>
      {supplierRows.map((row: any, index: number) => (
        <>
          <div className="supplier-name">
            <Select
              className="supplier-div"
              id="supplierId"
              label="Supplier Name"
              value={row.supplierId}
              onChange={(e: any) => handleSupplierChange(index, 'supplierId', e.target.value, e)}
            >
              {masterData?.supplierMaster &&
                masterData?.supplierMaster.map((data: any) => (
                  <Option
                    key={data.supplierId + index}
                    value={data.supplierId}
                    name={data.supplierName}
                  />
                ))}
            </Select>
          </div>
          <div className="supplier-tab" key={index} style={{ display: 'flex', gap: 20 }}>
            <FormField
              characterLimit={14}
              fieldHelper={{
                id: 'helper-msg-id',
              }}
              shouldValidate={shouldValidate}
              valid={
                row.ddsReferenceNumber &&
                (row.ddsReferenceNumber.length >= 14 || !/^\d+$/.test(row.ddsReferenceNumber))
                  ? 'error'
                  : false
              }
              validation={{
                id: 'error-msg-id',
                msg: 'Enter Valid Reference Number',
                type: 'error',
              }}
            >
              <InputField
                label="DDS Reference Number"
                iconPosition="leading"
                id={`dds-ref-${index}`}
                type="number"
                style={{ marginRight: 10 }}
                value={row.ddsReferenceNumber}
                onChange={(e) =>
                  handleSupplierChange(index, 'ddsReferenceNumber', e.target.value, e)
                }
                readOnly={props.isFilingImport}
              />
            </FormField>
            <FormField
              characterLimit={8}
              fieldHelper={{
                id: 'helper-id',
              }}
              shouldValidate={validateVerification}
              valid={
                row.ddsVerificationNumber &&
                (row.ddsVerificationNumber.length >= 8 || !/^\d+$/.test(row.ddsVerificationNumber))
                  ? 'error'
                  : false
              }
              validation={{
                id: 'error-msg-id',
                msg: 'DDS Number Should Be 13 Digits',
                type: 'error',
              }}
            >
              <InputField
                style={{ marginRight: 10 }}
                label={
                  index === 0 ? (
                    <>
                      DDS Verification Number
                      {row.ddsReferenceNumber && <span className="red-asterisk"> *</span>}
                    </>
                  ) : (
                    ''
                  )
                }
                required={!!row.ddsReferenceNumber}
                iconPosition="leading"
                id={`dds-ver-${index}`}
                type="number"
                value={row.ddsVerificationNumber}
                onChange={(e) =>
                  handleSupplierChange(index, 'ddsVerificationNumber', e.target.value, e)
                }
                readOnly={props.isFilingImport}
              />
            </FormField>
            <Select
              id="activitytype"
              label="Activity Type"
              value={selectedActivityType}
              onChange={handleActivityTypeChange}
              style={{ width: 'auto' }}
            >
              <Option value="0" name="Export" />
              <Option value="1" name="Import" />
              <Option value="2" name="Trade" />
              <Option value="3" name="Domestic" />
            </Select>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <DatePickerComponent heading="DDS filing date" />
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ height: 48 }}>
                <Button
                  // style={{ marginTop: 30 }}
                  iconPosition="leading"
                  key="validate"
                  size="xsmall"
                  text="Validate"
                  onClick={handleValidate}
                  disabled={props.isFilingImport}
                ></Button>
                <div style={{ height: '20px', display: 'flex', alignItems: 'center' }}>
                  {validationMessage && (
                    <div style={{ color: validationMessageColor }}>{validationMessage}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {countryActivity.map((row: any, index: number) => (
              <div key={index} style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                <Select
                  id={`countryId-${index}`}
                  label="Country of Activity"
                  value={row.countryOfActivity}
                  style={{ maxHeight: 100 }}
                  onChange={(e) =>
                    handleCountryOfActivity(index, 'countryOfActivity', e.target.value, e)
                  }
                >
                  {masterData?.countryMaster &&
                    masterData.countryMaster.map((data) => (
                      <Option
                        key={data.countryName + 'list2'}
                        value={data.countryCode}
                        name={data.countryName}
                      />
                    ))}
                </Select>
                <Select
                  id={`countryId-${index}`}
                  label="borderCross Country"
                  value={row.borderCrossCountry}
                  style={{ maxHeight: 100 }}
                  onChange={(e) =>
                    handleCountryOfActivity(index, 'borderCrossCountry', e.target.value, e)
                  }
                >
                  {masterData?.countryMaster &&
                    masterData.countryMaster.map((data) => (
                      <Option
                        key={data.countryName}
                        value={data.countryCode}
                        name={data.countryName}
                      />
                    ))}
                </Select>
              </div>
            ))}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 5,
                justifyContent: 'center',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: 120 }}>
                <Button size="small" onClick={props.handleFilingImport}>
                  File Without DDS
                </Button>
                <Button
                  iconPosition="trailing"
                  key="reset"
                  size="xsmall"
                  text="reset"
                  onClick={handleReset}
                ></Button>
              </div>
            </div>
            <Accordion>
              <AccordionItem title="Operator" id="Operator" open>
                {operatorRows.map((row: any, index: number) => (
                  <>
                    <div className="dds-tabs" key={index}>
                      <Select
                        className="operator-div"
                        id="Identifier"
                        label="Identifier"
                        value={row.operatorIdentifier}
                        onChange={(e: any) =>
                          handleOperator(index, 'operatorIdentifier', e.target.value, e)
                        }
                      >
                        <Option value="0" name="EORI" />
                        <Option value="1" name="VAT" />
                      </Select>
                      <InputField
                        style={{ marginRight: 10 }}
                        iconPosition="leading"
                        id={`article-number-${index}`}
                        type="text"
                        value={row.operatorValue}
                        onChange={(e) => handleOperator(index, 'operatorValue', e.target.value, e)}
                        label="Value"
                      />
                    </div>
                    <div className="dds-tabs" key={index}>
                      <InputField
                        style={{ marginRight: 10 }}
                        iconPosition="leading"
                        id={`article-number-${index}`}
                        type="text"
                        value={row.operatorName}
                        onChange={(e) => handleOperator(index, 'operatorName', e.target.value, e)}
                        label="Name"
                      />
                      <Select
                        id={`countryId-${index}`}
                        label="Country"
                        value={row.operatorCountry}
                        style={{ maxHeight: 100 }}
                        onChange={(e) =>
                          handleOperator(index, 'operatorCountry', e.target.value, e)
                        }
                      >
                        {masterData?.countryMaster &&
                          masterData.countryMaster.map((data) => (
                            <Option
                              key={data.countryName}
                              value={data.countryCode}
                              name={data.countryName}
                            />
                          ))}
                      </Select>
                      <TextArea
                        id="example-id"
                        label="Address"
                        placeholder="Address"
                        onChange={(e) =>
                          handleOperator(index, 'operatorAddress', e.target.value, e)
                        }
                      />
                    </div>
                  </>
                ))}
              </AccordionItem>
            </Accordion>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 5,
              justifyContent: 'center',
            }}
          ></div>
        </>
      ))}
    </div>
  );
};

export default SupplierDetails;
