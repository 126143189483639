import Combobox, { MenuItem } from '@ingka/combobox';
import React, { useState, useEffect } from 'react';
import InputField from '@ingka/input-field';
import FormField from '@ingka/form-field';
import styled from 'styled-components';
import { contentDelimiterStyles, Page } from 'components/layout';
import Commodities from 'pages/Commodities/commodities';
import Button from '@ingka/button';
import { Select, Option } from '@ingka/select';
import { manualSubmit, resetManualEntry } from 'Action/manualSubmitAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import Toast from '@ingka/toast';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/forms/dist/style.css';
import { useNavigate } from 'react-router-dom';
import AssociatedDds from 'pages/AssociatedDDS/associatedDds';
import { masterDataManual } from 'Action/manualDropDownAction';
import 'react-datepicker/dist/react-datepicker.css';
import SupplierDetails from 'pages/SupplierDetails/supplierDetails';

const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  justify-content: space-between;
`;
const SecondDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  justify-content: space-between;
`;

const ManualEntry = (props: any) => {
  const { masterData } = useSelector((state: RootState) => state.dropDownMasterData);
  const { manualFetchData, manualValidateSubmit } = useSelector(
    (state: RootState) => state.manualValidateDetails
  );
  const [toastOpen, setToastOpen] = useState<any>(false);
  const [isValidate, setIsValidate] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [supplierRows, setSupplierRows] = useState([
    {
      supplierId: '',
      ddsReferenceNumber: '',
      ddsVerificationNumber: '',
      ddsFilingDate: '',
      ddsActivityType: '',
    },
  ]);
  const [productRows, setproductRows] = useState([
    { productName: '', productIdType: '', productId: '' },
  ]);
  const [oldDdsRows, setOldDdsRows] = useState([
    { DdsFirstProduction: '', verificationNumber: '' },
  ]);
  const [commodityRows, setCommodityRows] = useState([
    {
      hsHeader: null,
      descriptionOfGoods: '',
      netWeight: null,
      numberofUnits: null,
      volume: null,
      universalUnit: '',
    },
  ]);

  // setCommodityRows([...commodityRows,manualFetchData?.data.statementInfo?.commodities[0]])
  const [productionPlaceRows, setProductionPlaceRows] = useState([
    { productionPlace: '', type: '', coordinates: null, area: null },
  ]);
  const [producerRows, setProducerRows] = useState([{ producerName: '', producerCountry: '' }]);
  const [selectedSupplier, setselectedSupplier] = useState<string>('');
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [validateVerification, setValidateVerification] = useState(false);
  const [isFilingImport, setIsFilingImport] = useState(false);
  const [selectedActivityType, setSelectedActivityType] = useState('');
  const [submitToastMessage, setSubmitToastMessage] = useState('');
  const [frnId, setFrnId] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [isManualSubmit, setIsManualSubmit] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const { manualEntryData } = useSelector((state: RootState) => state.manualEntrySubmitData);
  const { error, errorMessage, errorType } = useSelector((state: RootState) => state.error);

  const navigate = useNavigate();

  useEffect(() => {
    if (manualFetchData) {
      const transformData = ({ commodities }) => {
        return commodities.map(
          ({
            hsHeading,
            descriptionOfGoods,
            goodsMeasure: { volume, netWeight, numberofUnits },
            producers,
          }) => ({
            hsHeading: hsHeading || null,
            descriptionOfGoods: descriptionOfGoods || '',
            goodsMeasure: {
              volume: volume || 0,
              netWeight: netWeight || 0,
              numberofUnits: numberofUnits || 0,
            },
            producers: producers
              ? producers.map(({ producerCountry, geometryGeoJson }) => ({
                  producerCountry: producerCountry || null,
                  geometryGeoJson: geometryGeoJson || null,
                }))
              : [],
          })
        );
      };

      const transformProductionPlaceData = ({ commodities }) => {
        const producers = commodities.flatMap((commodity) => commodity.producers || []);
        return producers.flatMap(
          (producer) =>
            producer.geometryGeoJson?.features.map(
              ({ properties: { area }, geometry: { type, coordinates } }) => ({
                area: area || null,
                type: type || null,
                coordinates: coordinates || [],
              })
            ) || []
        );
      };

      const transformedData = transformData(manualFetchData);
      const transformedProductionPlace = transformProductionPlaceData(manualFetchData);

      setCommodityRows(transformedData);
      setIsAccordionOpen(true);
      setProductionPlaceRows([...transformedProductionPlace]);
    }
  }, [manualFetchData]);

  useEffect(() => {
    setToastOpen(false);

    if (manualEntryData) {
      setToastOpen(true);
      dispatch({
        type: 'MANUAL_SUCCESS',
        payload: null,
      });
      return;
    }
    if (errorMessage && errorType === 'manualerror') {
      setToastOpen(true);
      // dispatch({
      //   type: 'MANUAL_ERROR',
      //   payload: null,
      // });
    }
  }, [manualEntryData, errorMessage]);

  useEffect(() => {
    if (toastOpen && manualEntryData) {
      const timer = setTimeout(() => {
        dispatch({
          type: 'MANUAL_SUBMIT',
          payload: null,
        });

        navigate('/approval');
        setToastOpen(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [toastOpen, manualEntryData]);

  useEffect(() => {
    dispatch(masterDataManual());
  }, []);

  manualEntryData?.supplierSuccessResponse &&
    setSubmitToastMessage(
      `DDS sent to Pending approval and frnId is:${manualEntryData?.supplierSuccessResponse?.frnId}`
    );
  //  const frnId = manualEntryData?.supplierSuccessResponse?.frnId;
  //  setFrnId(frnId);
  useEffect(() => {
    if (isManualSubmit && manualSubmit) {
      if (error?.error?.response?.data?.errorCode === 417) {
        const errorMessage = error?.error?.response?.data?.responses[0]?.errorMessage;
        setSubmitToastMessage(`Error: ${errorMessage}`);
      }
    }
    setToastOpen(true);
  }, [manualEntryData, error]);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(frnId).then(() => {
      setIsCopied(true);
      // setTimeout(() => setIsCopied(false), 2000);
    });
  };
  const handleFilingImport = () => {
    setIsFilingImport(true);
  };
  const handleManualSubmit = async () => {
    setToastOpen(false);
    setIsManualSubmit(true);
    // const submitResponse = await dispatch(manualSubmit(payload));
  };

  const buttonData = [
    { label: 'Cancel', onClick: () => console.log('123') },
    { label: 'Submit', onClick: handleManualSubmit },
  ];
  const onClose = () => {
    setToastOpen(false);
  };

  const handleAddCommodityRow = () => {};

  return (
    <div style={{ maxWidth: 'auto' }}>
      <Page style={{ maxWidth: 'fit-content' }}>
        <div className="manual-body">
          <FormField>
            <div>
              <SupplierDetails
                index={0}
                masterData={masterData}
                isFilingImport={isFilingImport}
                handleFilingImport={handleFilingImport}
              />
            </div>
            {(isValidate || isFilingImport) && (
              <>
                <AssociatedDds oldDdsRows={oldDdsRows} setOldDdsRows={setOldDdsRows} />
                <div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleAddCommodityRow} size="xsmall">
                      Add Commodities
                    </Button>
                    <Button onClick={handleAddCommodityRow} size="xsmall">
                      Remove commodities
                    </Button>
                  </div>
                  <Commodities
                    commodityRows={commodityRows}
                    setCommodityRows={setCommodityRows}
                    productionPlaceRows={productionPlaceRows}
                    setProductionPlaceRows={setProductionPlaceRows}
                    producerRows={producerRows}
                    setProducerRows={setProducerRows}
                    isAccordionOpen={isAccordionOpen}
                  />
                </div>
                <div
                  style={{
                    marginTop: '10px',
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'flex-end',
                  }}
                >
                  {buttonData.map((button, index) => (
                    <Button
                      key={index}
                      className="demo-pill--stacking-story"
                      iconPosition="leading"
                      text={button.label}
                      onClick={handleManualSubmit}
                      size="small"
                      type="emphasised"
                    />
                  ))}
                </div>
              </>
            )}
          </FormField>

          {manualEntryData && (
            <Toast
              text={
                <>
                  <strong>{submitToastMessage}</strong>
                </>
              }
              isOpen={toastOpen}
              actionClick={() => {}}
              onCloseRequest={onClose}
              ariaLabelCloseBtn="Dismiss notification"
            >
              {frnId && <Button onClick={copyToClipboard}>{isCopied ? '++' : 'Copy frnId'}</Button>}
            </Toast>
          )}
          {manualSubmit && isManualSubmit && error && (
            <Toast
              text={
                <>
                  <strong>{submitToastMessage}</strong>
                </>
              }
              isOpen={toastOpen}
              actionClick={() => {}}
              onCloseRequest={onClose}
              ariaLabelCloseBtn="Dismiss notification"
            />
          )}
        </div>
      </Page>
    </div>
  );
};
export default ManualEntry;
