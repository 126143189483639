import React, { useState, useEffect, FC } from 'react';
import Search from '@ingka/search';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/search/dist/style.css';
import '@ingka/focus/dist/style.css';

interface GlobalSearchProps {
  onSearch: (query: string) => void;
  tabChange: (tab: any) => void;
  tabChanged: boolean;
  setTabChanged: (changed: boolean) => void;
}

const GlobalSearch: FC<GlobalSearchProps> = ({
  onSearch,
  tabChange,
  tabChanged,
  setTabChanged,
}) => {
  const [query, setQuery] = useState('');

  const handleSearch = () => {
    onSearch(query);
  };

  const handleClear = () => {
    setQuery('');
    onSearch('');
  };

  useEffect(() => {
    if (tabChanged) {
      setQuery('');
      setTabChanged(false);
    }
  }, [tabChanged, setTabChanged]);

  return (
    <div>
      <Search
        ariaLabel="Search for supplier name,ref number and commodity."
        clearBtnText="Clear search field"
        id="search"
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search"
        submitBtnText="Submit"
        onSearch={handleSearch}
        onClear={handleClear}
        value={query}
      />
    </div>
  );
};

export default GlobalSearch;
