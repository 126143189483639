import React, { useState } from 'react';
import AspectRatioBox from '@ingka/aspect-ratio-box';
import Checkbox from '@ingka/checkbox';
import TextArea from '@ingka/text-area';
import Combobox, { MenuItem } from '@ingka/combobox';
import Button from '@ingka/button';
import TableLayout from '../TableLayout/tableLayout';
import FormField from '@ingka/form-field';
import './detailsLayout.scss';
import Tooltip from '@ingka/tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
const DetailsLayout = (props: any) => {
  const {
    data,
    buttonData,
    isCheckBoxDisable,
    isTextAreaDisbale,
    fedsaReference,
    ddsReference,
    pendingApprovalData,
    comment,
    setComment,
    craStatus,
    glaStatus,
    acceptRiskChecked,
    setAcceptRiskChecked,
    autoApprove,
    setAutoApprove,
  } = props;
  const isGLAResultAvailable = useSelector((state: RootState) => state.gla.isGLAResultAvailable);
  const [shouldValidate, setShouldValidate] = useState(false);
  const handleTextChange = (event) => {
    const text = event.target.value;
    setComment(text);
    if (text.length > 300) {
      setShouldValidate(true);
    } else {
      setShouldValidate(false);
    }
  };
  const handleAcceptRiskChange = (event) => {
    setAcceptRiskChecked(event.target.checked);
  };
  const handleAutoApprove = (event) => {
    setAutoApprove(event.target.checked);
  };
  return (
    <div>
      <div className="display-details">
        <div>
          <span style={{ fontWeight: 'bold' }}>
            Fedsa Reference Number : {fedsaReference || '1234567890'}
          </span>
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>DDS Reference Number : {ddsReference || ''}</span>
        </div>
        <TableLayout
          items={data}
          isLeftSide={false}
          fedsaReference={fedsaReference}
          pendingApprovalData={pendingApprovalData}
        />
      </div>

      <div style={{ marginTop: '10px' }}>
        <Checkbox
          id="example-id"
          label="Auto Approve for all the products in DDS"
          name="example-group"
          value="checkbox 1"
          disabled={!craStatus || !glaStatus}
          checked={autoApprove}
          onChange={handleAutoApprove}
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        <Checkbox
          id="example-id"
          label="I have reviewed the risk and agree to proceed with submission"
          name="example-group"
          value="checkbox 1"
          disabled={(craStatus && glaStatus) || isGLAResultAvailable || isCheckBoxDisable}
          checked={acceptRiskChecked}
          onChange={handleAcceptRiskChange}
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        <FormField
          characterLimit={300}
          fieldHelper={{
            id: 'helper-msg-id',
            msg: 'Maximum 300 characters',
            type: '',
          }}
          shouldValidate={shouldValidate}
          valid="error"
          validation={{
            id: 'error-msg-id',
            msg: 'maximum characters cannot exceeds 300',
            type: 'error',
          }}
        >
          <TextArea
            id="example-id"
            label="Notes"
            disabled={isTextAreaDisbale}
            placeholder="Max text limit is 300."
            value={comment}
            onChange={handleTextChange}
          />
        </FormField>
      </div>
      <div>
        <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
          {buttonData?.map((button, index) =>
            (!craStatus || !glaStatus) && !acceptRiskChecked && button.label === 'Submit' ? (
              <Tooltip
                key={index}
                position="top"
                tooltipText="Do the risk mitigation and proceed the submit"
              >
                <span>
                  <Button
                    className="demo-pill--stacking-story"
                    iconPosition="leading"
                    text={button.label}
                    onClick={(e) => button.onClick(e, button, index)}
                    size="small"
                    type="emphasised"
                    disabled={button.disabled && !(acceptRiskChecked && button.label === 'Submit')}
                  />
                </span>
              </Tooltip>
            ) : (
              <Button
                key={index}
                className="demo-pill--stacking-story"
                iconPosition="leading"
                text={button.label}
                onClick={(e) => button.onClick(e, button, index)}
                size="small"
                type="emphasised"
                disabled={button.disabled && !(acceptRiskChecked && button.label === 'Submit')}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsLayout;
