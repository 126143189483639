import React from 'react';
import { AuthenticatedTemplate, MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'pages/NotFound/notFound';
import Home from 'pages/Home';
import AppHeader from 'components/AppHeader';
import { AboutPage } from 'pages/About/about';
import AppFooter from 'components/AppFooter';
import CustomersPage from 'pages/Customers';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'appInsights';
import { ToastAnchor } from 'components/ToastAnchor';
import { Fedsa } from 'pages/Fedsa';
import { DetailPage } from 'pages/Detail/Detail';
import ManualEntry from 'pages/ManualEntry/manualEntry';
import styled from 'styled-components';

const queryClient = new QueryClient();

const PageContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Content = styled.div`
  grid-row: 2;
`;

const App: React.FC<{ pca: IPublicClientApplication }> = (props) => (
  <MsalProvider instance={props.pca}>
    <AppInsightsErrorBoundary
      onError={() => <h1>Something went wrong</h1>}
      appInsights={reactPlugin}
    >
      <AppInsightsContext.Provider value={reactPlugin}>
        <QueryClientProvider client={queryClient}>
          <PageContainer>
            <AppHeader />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/customers" element={<CustomersPage />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/fileupload" element={<Fedsa />} />
              <Route path="/approval" element={<DetailPage />} />
              <Route path="/manualentry" element={<ManualEntry />} />
            </Routes>
            <AppFooter />
            <ToastAnchor />
          </PageContainer>
        </QueryClientProvider>
      </AppInsightsContext.Provider>
    </AppInsightsErrorBoundary>
  </MsalProvider>
);

export default App;
