import { SET_PRODUCTION_PLACE_ROWS, SET_PRODUCER_ROWS, ADD_PRODUCER_ACCORDION } from 'Action/types';

const initialState = {
  productionPlaceRows: [{ productionPlace: '', type: '', coordinates: null, area: null, id: null }],
  producerRows: [{ producerName: '', producerCountry: '' }],
  producerAccordions: [{ producerRows: [{ producerName: '', producerCountry: '' }] }],
};

const producerStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTION_PLACE_ROWS:
      return {
        ...state,
        productionPlaceRows: action.payload,
      };
    case SET_PRODUCER_ROWS:
      return {
        ...state,
        producerAccordions: state.producerAccordions.map((accordion, index) =>
          index === action.payload.accordionIndex
            ? { ...accordion, producerRows: action.payload.rows }
            : accordion
        ),
      };
    case ADD_PRODUCER_ACCORDION:
      return {
        ...state,
        producerAccordions: [
          ...state.producerAccordions,
          { producerRows: [{ producerName: '', producerCountry: '' }] },
        ],
      };
    default:
      return state;
  }
};

export default producerStateReducer;
