import { callAPI } from 'Action';
import { MANUAL_SUPPLIER_VALIDATE, MANUAL_SUPPLIER_DATA } from './types';
import { appConfig } from 'appConfig';
import { MASTER_SUPPLIER_VALIDATE } from './PathConstants';

export const manualValidate = (value: any, actionType: any) => async (dispatch: any) => {
  let url = appConfig.baseUrl + MASTER_SUPPLIER_VALIDATE;
  let reqObj = value;

  const options = {
    url,
    actionType,
    method: 'POST',
    reqObj,
    dispatch,
  };
  let data = await callAPI(options);
  if (data) {
    dispatch({
      type: MANUAL_SUPPLIER_VALIDATE,
      payload: data,
    });
  } else {
    dispatch({
      type: 'VALIDATE_ERROR',
      payload: null,
    });
  }
  return data;
};
