import { msalInstance } from 'utils/msalInstance';

import { InteractionRequiredAuthError } from '@azure/msal-browser';

export async function acquireAccessToken(apiScopes: string[]) {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    window.location.href = '/';
    return;
  }
  const request = {
    scopes: apiScopes,
    account: activeAccount || accounts[0],
  };

  const authResult = await msalInstance.acquireTokenSilent(request).catch(async (error: any) => {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return await msalInstance.acquireTokenPopup(request).catch((error) => {
        throw new Error(error);
      });
    }
  });
  if (
    authResult === undefined ||
    authResult.accessToken === undefined ||
    authResult.accessToken === null
  ) {
    window.location.href = '/';
    msalInstance.logoutPopup().then(() => window.location.reload());
  } else {
    return authResult.accessToken;
  }
}
