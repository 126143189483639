import React, { useRef, useState, useEffect } from 'react';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import InputField from '@ingka/input-field';
import RadioButtonGroup from '@ingka/radio-button-group';
import './product.scss';
import Button from '@ingka/button';
import Accordion, { AccordionItem } from '@ingka/accordion';
import '@ingka/svg-icon/style.scss';
import '@ingka/accordion/style.scss';
import '@ingka/focus/style.scss';
import DatePickerComponent from 'pages/DatePickerComponent/datePickerComponent';

const Products = (props: any) => {
  const [previousRows, setPreviousRows] = useState([]);

  const prevProps = useRef();
  useEffect(() => {
    prevProps.current = props;
  }, []);

  const handleDeleteRow = (index) => {
    const newPreviousRows = previousRows.filter((_, i) => i !== index);
    setPreviousRows(newPreviousRows);
  };

  const handleInputChange = (index: number, field: any, value: any) => {
    const newFormRows = props.productRows?.map((row: any, i: any) =>
      i === index ? { ...row, [field]: value } : row
    );
    props.setproductRows([...newFormRows]);
  };

  return (
    <div>
      <div className="maindiv" style={{ height: 'auto' }}>
        {props.productRows?.map((row, index) => (
          <div className="product-tabs" key={index}>
            <InputField
              style={{ marginRight: 10 }}
              iconPosition="leading"
              id={`product-name-${index}`}
              type="text"
              value={row.productName}
              onChange={(e) => handleInputChange(index, 'productName', e.target.value)}
              label={index === 0 ? 'Product Name' : ''}
            />
            <InputField
              style={{ marginRight: 10 }}
              iconPosition="leading"
              id={`article-number-${index}`}
              type="text"
              value={row.productId}
              onChange={(e) => handleInputChange(index, 'productId', e.target.value)}
              label={index === 0 ? 'GTIN' : ''}
            />
            <InputField
              // style={{ marginRight: 10 }}
              iconPosition="leading"
              id={`item-supplier${index}`}
              type="text"
              value={row.itemSupplier}
              onChange={(e) => handleInputChange(index, 'itemSupplier', e.target.value)}
              label={index === 0 ? 'Item Supplier' : ''}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <DatePickerComponent heading="Valid for Batches from" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <DatePickerComponent heading="Valid for Batches until" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
