import React, { useState, useEffect } from 'react';
import './saveForLater.scss';
import TableLayout from 'components/SharedLayout/TableLayout/tableLayout';
import DetailsLayout from 'components/SharedLayout/DetailsLayout/detailsLayout';
import { getRiskDetails } from 'Action/statusDetailsAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import { getFEDSAReferenceNumber } from 'utils/common';
import { getEudrSubmissionResult } from 'Action/eudrSubmissionAction';
import Toast from '@ingka/toast';

const SaveForLater = (props: any) => {
  const { queries, setQuery, tabChange, handleTabChange, submittedToEUTabRef, items } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { pendingApprovalList, riskDetailList, saveForLaterList } = useSelector(
    (state: RootState) => state.statusDetails
  );
  // const selectedSaveForLaterData = useSelector(state => state.saveForLaterList);
  const [fedsaReference, setFEDSAReference] = useState<any>();
  const [ddsReference, setDDSReference] = useState<any>();
  const [showDetailsData, setShowDetailsData] = useState(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [riskDetailsById, setRiskDetailsById] = useState<any>([]);
  const [toastOpen, setToastOpen] = useState<any>(false);
  const [toastMessage, setToastMessage] = useState('');
  const [comment, setComment] = useState('');
  const [acceptRiskChecked, setAcceptRiskChecked] = useState(false);
  const [autoApprove, setAutoApprove] = useState(false);
  const [craStatus, setCraStatus] = useState(true);
  const [glaStatus, setGlaStatus] = useState(true);
  const [SFLlistData, setSFLListData] = useState<any>([]);

  const mapping = {
    isEntryFromAPI: 'Source',
    supplierName: 'Supplier Name',
    fedsa_RefNum: 'Ref_Num',
    articles: 'Supplier Article Number  / GTIN',
    goodsDescription: 'Trade Item Description',
    receivedOn: 'DDS Received On',
    fedsaStatus: 'Current status',
    pdf: 'PDF',
  };
  const riskDetailMapping = {
    commodities: 'Commodities',
    country: 'Country',
    productionPlaceCount: 'Production Place',
    cra: 'CRA',
    gla: 'GLA',
  };

  useEffect(() => {
    if (queries.length === 0) {
      let items = mappedItems(saveForLaterList, mapping);
      setSFLListData(items);
    } else {
      let items = mappedItems(filteredData, mapping);
      setSFLListData(items);
    }
  }, [filteredData, saveForLaterList, queries]);

  useEffect(() => {
    let items = mappedItems(riskDetailList, riskDetailMapping);
    setRiskDetailsById(items);
    if (riskDetailList?.length > 0) {
      const craPassed = riskDetailList?.every((item) => item?.cra?.isPassed);
      const glaPassed = riskDetailList?.every((item) => item?.gla?.isPassed);
      setCraStatus(craPassed);
      setGlaStatus(glaPassed);
    }
  }, [riskDetailList]);
  useEffect(() => {
    if (saveForLaterList) {
      const filtered = saveForLaterList.filter(
        (item) =>
          item.supplierName?.toLowerCase().includes(queries.toLowerCase()) ||
          item.articles?.toLowerCase().includes(queries.toLowerCase()) ||
          item.fedsa_RefNum?.toLowerCase().includes(queries.toLowerCase())
      );

      setFilteredData(filtered);
    }
  }, [queries, saveForLaterList]);

  const mappedItems = (itemList, mappingKey) => {
    return itemList?.map((item) => {
      return Object.keys(mappingKey).reduce((acc, key) => {
        acc[mappingKey[key]] = item[key] !== undefined ? item[key] : null; // Set to null if the key does not exist
        return acc;
      }, {});
    });
  };

  const onClose = () => {
    setToastOpen(false);
  };
  const resetAcceptRiskChecked = () => {
    setAcceptRiskChecked(false);
  };

  const resetAutoApprove = () => {
    setAutoApprove(false);
  };
  const submit = async () => {
    if (fedsaReference) {
      try {
        const response = await dispatch(
          getEudrSubmissionResult({ id: fedsaReference, description: comment })
        );

        if (response && response?.request?.status === 200) {
          setToastOpen(true);
          setToastMessage('Submission Success');
          setTimeout(() => {
            setToastOpen(false);
            if (submittedToEUTabRef.current) {
              submittedToEUTabRef.current.click();
            }
          }, 2000);
        } else {
          setToastOpen(true);
          setToastMessage('Submission failed');
          return;
        }
      } catch (error) {
        setToastOpen(true);
        setToastMessage('Submission failed');
      }
    }
  };

  const invalidate = (e: any, button: any, index: any) => {
    console.log(e, button, index);
  };
  const onSearch = (searchQuery) => {
    setQuery(searchQuery);
  };
  const onShowDetails = (value, fedsa_RefNum) => {
    if (fedsa_RefNum) {
      setFEDSAReference(fedsa_RefNum);
      const selectedRow = getFEDSAReferenceNumber(saveForLaterList, fedsa_RefNum);
      setDDSReference(selectedRow.dds_RefNum);
      dispatch(getRiskDetails(selectedRow.fedsa_RefNum));
    }
    setShowDetailsData(value);
  };
  const buttonData = [
    {
      label: 'Submit',
      onClick: submit,
      disabled: !craStatus || (!glaStatus && !acceptRiskChecked),
    },
    { label: 'Invalidate', onClick: invalidate },
  ];
  return (
    <div className="details-container">
      <div className="table-container">
        <div className="table-list-view">
          {saveForLaterList && SFLlistData?.length > 0 && (
            <TableLayout
              items={SFLlistData}
              isLeftSide={true}
              onSearch={onSearch}
              onShowDetails={onShowDetails}
              pendingApprovalData={filteredData}
              tabChange={tabChange}
              acceptRiskChecked={acceptRiskChecked}
              setAcceptRiskChecked={setAcceptRiskChecked}
              resetAcceptRiskChecked={resetAcceptRiskChecked}
              resetAutoApprove={resetAutoApprove}
              autoApprove={autoApprove}
              setAutoApprove={setAutoApprove}
              setComment={setComment}
            />
          )}
        </div>
      </div>
      {showDetailsData && riskDetailList?.length > 0 && riskDetailsById?.length > 0 && (
        <div className="detail-list">
          <DetailsLayout
            data={riskDetailsById}
            buttonData={buttonData}
            isCheckBoxDisable={false}
            isTextAreaDisbale={false}
            isDropdown={true}
            fedsaReference={fedsaReference}
            ddsReference={ddsReference}
            pendingApprovalData={filteredData}
            comment={comment}
            setComment={setComment}
          />
        </div>
      )}
      {toastOpen && (
        <Toast
          text={<strong>{toastMessage}</strong>}
          isOpen={toastOpen}
          actionClick={() => {}}
          onCloseRequest={onClose}
          ariaLabelCloseBtn="Dismiss notification"
        />
      )}
    </div>
  );
};

export default SaveForLater;
