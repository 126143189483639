import { combineReducers } from 'redux';
import fileUploadReducer from './fileUploadReducer';
import manualSubmitReducer from './manualSubmitReducer';
import statusDetailsReducer from './statusDetailsReducer';
import errorReducer from './manualValidateReducer';
import pendingApprovalReducer from './pendingApprovalReducer';
import manualDropDownReducer from './manualDropDownReducer';
import manualValidateReducer from './manualValidateReducer';
import producerStateReducer from './producerDetailsStateReducer';
import glaReducer from './esriResponseReducer';
export default combineReducers({
  fileUploadDetails: fileUploadReducer,
  manualEntrySubmitData: manualSubmitReducer,
  dropDownMasterData: manualDropDownReducer,
  error: errorReducer,
  statusDetails: statusDetailsReducer,
  manualValidateDetails: manualValidateReducer,
  saveForLaterList: statusDetailsReducer,
  submittedToEuList: statusDetailsReducer,
  archivedList: statusDetailsReducer,
  invalidList: statusDetailsReducer,
  producerAndProductionPlaceState: producerStateReducer,
  gla: glaReducer,
});
