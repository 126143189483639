import React, { useState, useCallback } from 'react';
import Table, { TableBody, TableHeader } from '@ingka/table';
import Pill from '@ingka/pill';
import document from '@ingka/ssr-icon/paths/document';
import arrowDownToBase from '@ingka/ssr-icon/paths/arrow-down-to-base';
import chevronRightSmall from '@ingka/ssr-icon/paths/chevron-right-small';
import chevronLeftSmall from '@ingka/ssr-icon/paths/chevron-left-small';
import './tableLayout.scss';
import Status from '@ingka/status';
import { getFEDSAReferenceNumber, statusCheck } from 'utils/common';
import Search from '@ingka/search';
import Tooltip from '@ingka/tooltip';
import { useDispatch } from 'react-redux';
import { downloadDDSExcel, downloadDDSPdf } from 'Action/statusDetailsAction';
import { AppDispatch, RootState } from 'Store/store';
import Modal, { ModalHeader, Theatre } from '@ingka/modal';
import { ModalData } from 'components/Modal/modalData';
import Products from 'pages/Products/products';
import SSRIcon from '@ingka/ssr-icon';
import Pagination from 'components/Pagination/pagination';
import { setGLAResultAvailable } from 'Action/esriResponseAction';

const TableLayout = (props: any) => {
  const {
    items,
    header,
    isLeftSide,
    pendingApprovalData,
    selectedRow,
    onSearch,
    onShowDetails,
    fedsaReference,
    acceptRiskChecked,
    setAcceptRiskChecked,
    resetAcceptRiskChecked,
    resetAutoApprove,
    autoApprove,
    setAutoApprove,
    setComment,
  } = props;
  const headers =
    items && items.length > 0 ? Object.keys(items[0]).filter((header) => header !== 'Ref_Num') : [];
  const capitalizeHeader = (header) =>
    header
      .replace(/_/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  const MAX_ARTICLES_LENGTH = 20;
  const ITEMS_PER_PAGE = 7;
  const [highlightedRow, setHighlightedRow] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDetails, setModalDetails] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableClick, setTableClick] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const downloadPdf = (e: any, header: any, item: any, type: any) => {
    e.stopPropagation();
    let id = getFEDSAReferenceNumber(pendingApprovalData, fedsaReference || item.Ref_Num);
    if (type === 'pdf') {
      dispatch(downloadDDSPdf(id.fedsa_RefNum));
    } else {
      dispatch(downloadDDSExcel(id.fedsa_RefNum));
    }
  };

  const status = (products: any) => {
    let stockAvailablity = statusCheck(products);
    return (
      <Status
        statusColor={stockAvailablity.color as any}
        statusDotPosition="leading"
        small={false}
        onClick={stockAvailablity.color === 'green' ? () => {} : () => onClickModal(products)}
      />
    );
  };
  const glaStatus = useCallback((item, header) => {
    const isPassed = item[header]?.isPassed;
    const passedCount = item[header]?.passedCount;
    const failedCount = item[header]?.failedCount;

    if (isPassed !== undefined) {
      dispatch({
        type: 'SET_GLA_RESULT_AVAILABLE',
        payload: false,
      });
      return isPassed ? (
        <span style={{ color: 'green' }}>{passedCount}</span>
      ) : (
        <span style={{ color: 'red' }}>{failedCount}</span>
      );
    } else if (passedCount === undefined && failedCount === undefined) {
      dispatch({
        type: 'SET_GLA_RESULT_AVAILABLE',
        payload: true,
      });
      return (
        <Status
          label="Awaiting GLA result from ESRI"
          statusColor="grey"
          statusDotPosition="leading"
        />
      );
    }
  }, []);
  const onClickModal = (products) => {
    setModalDetails(products);
    setModalOpen(true);
  };
  const renderTableDetails = (header, item) => {
    if (header === 'PDF') {
      return (
        <Pill
          size="xsmall"
          onClick={(e) => downloadPdf(e, header, item, 'pdf')}
          ssrIcon={arrowDownToBase}
        />
      );
    } else if (header === 'CRA') {
      return <div className="tooltip-demo__container">{status(item)}</div>;
    } else if (header === 'GLA') {
      return (
        <span
          style={{ color: item?.CRA?.isPassed === false ? 'red' : 'green', cursor: 'pointer' }}
          onClick={(e) => downloadPdf(e, header, item, 'excel')}
        >
          {glaStatus(item, header)}
        </span>
      );
    } else if (header === 'articles') {
      const articlesText = item[header];
      const showTooltip = articlesText?.length > MAX_ARTICLES_LENGTH;
      const displayText = showTooltip
        ? `${articlesText?.slice(0, MAX_ARTICLES_LENGTH)}...`
        : articlesText;
      return showTooltip ? (
        <Tooltip describedById="tooltip-example" position="top" tooltipText={articlesText}>
          <span>{displayText}</span>
        </Tooltip>
      ) : (
        displayText
      );

      // return displayText;
    } else if (header === 'commodities') {
      const articlesText = item[header];
      const showTooltip = articlesText?.length > MAX_ARTICLES_LENGTH;
      const displayText = showTooltip
        ? `${articlesText.slice(0, MAX_ARTICLES_LENGTH)}...`
        : articlesText;
      return showTooltip ? (
        <Tooltip describedById="tooltip-example" position="top" tooltipText={articlesText}>
          <span>{displayText}</span>
        </Tooltip>
      ) : (
        displayText
      );
    } else if (header === 'Source') {
      return item[header] === false ? 'Manual' : 'API';
    } else {
      return item[header];
    }
  };
  const onTableClick = (item: any, index: any) => {
    if (isLeftSide) {
      resetAcceptRiskChecked();
      setComment && setComment('');
      resetAutoApprove && resetAutoApprove();
      if (highlightedRow === index && tableClick) {
        setTableClick(false);
        setHighlightedRow(index);
        props?.onShowDetails(false);
      } else {
        if (!tableClick) {
          setTableClick(true);
        }
        setHighlightedRow(index);
        props?.onShowDetails(true, item.Ref_Num);
      }
    }
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    props.onShowDetails(false);
    setHighlightedRow('');
  };

  const paginatedItems = items.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );
  const totalPages = Math.ceil(items.length / ITEMS_PER_PAGE);

  return (
    <>
      <div style={{ width: '100%' }}></div>
      <div>
        <div style={{ marginTop: '10px', overflowY: 'auto' }}>
          <Table className="table" fullWidth>
            <TableHeader sticky>
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{capitalizeHeader(header)}</th>
                ))}
              </tr>
            </TableHeader>

            <TableBody striped>
              {paginatedItems.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => onTableClick(item, index)}
                  className={
                    highlightedRow === index || item === selectedRow ? 'highlighted-row' : ''
                  }
                >
                  {headers.map((header, idx) => (
                    <td key={idx}>{renderTableDetails(header, item)}</td>
                  ))}
                </tr>
              ))}
            </TableBody>
          </Table>
        </div>
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            items={items}
          />
        )}
      </div>

      <div style={{ position: 'absolute' }}>
        {modalOpen && modalDetails && (
          <ModalData
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            modalDetails={modalDetails}
          />
        )}
      </div>
    </>
  );
};

export default TableLayout;
