import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calender from '@ingka/ssr-icon/paths/calendar';
import InputField from '@ingka/input-field';
import './datePickerComponent.scss';
const DatePickerComponent = ({ heading }: { heading: string }) => {
  const [startDate, setStartDate] = useState(null);
  const handleDateChange = (date) => {
    setStartDate(date);
  };

  return (
    <>
      <span>{heading}</span>
      <div className="input-field">
        {/* <InputField id={'datepickerglobal'} type={'number'}> */}
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          placeholderText="yyyy-MM-dd"
          dateFormat="yyyy-MM-dd"
          className="date-picker-global"
        />
        {/* </InputField> */}
        {/* <calender/> */}
      </div>
    </>
  );
};

export default DatePickerComponent;
