import { appConfig } from 'appConfig';
import {
  ARCHIVED_LIST,
  INVALID_LIST,
  PENDING_APPROVAL_LIST,
  RISK_DETAILS,
  SAVE_FOR_LATER_LIST,
  SUBMITTED_TO_EU_LIST,
} from './types';
import { callAPI } from 'Action';
import { downloadExeclFile, downloadPdfFile } from 'utils/common';

export const getStatusList = (tabChange) => async (dispatch) => {
  let url = appConfig.baseUrl + 'ddsinfo';
  if (typeof tabChange != 'object') {
    url += `?Status=${tabChange}`;
  } else {
    return;
  }
  const options = {
    url,
    method: 'GET',
    dispatch: null,
  };
  let list: any = await callAPI(options);

  if (tabChange === 'Pending') {
    dispatch({
      type: PENDING_APPROVAL_LIST,
      payload: list.data,
    });
  } else if (tabChange === 'SaveForLater') {
    dispatch({
      type: SAVE_FOR_LATER_LIST,
      payload: list.data,
    });
  } else if (tabChange === 'SubmittedForEUDR') {
    dispatch({
      type: SUBMITTED_TO_EU_LIST,
      payload: list.data,
    });
  } else if (tabChange === 'Archived') {
    dispatch({
      type: ARCHIVED_LIST,
      payload: list.data,
    });
  } else {
    dispatch({
      type: INVALID_LIST,
      payload: list.data,
    });
  }
};

export const getRiskDetails = (fedsaId: any) => async (dispatch) => {
  let url = `${appConfig.baseUrl}riskdetail/${fedsaId}`;
  const options = {
    url,
    method: 'GET',
    dispatch: null,
  };
  let riskList = await callAPI(options);
  if (riskList) {
    dispatch({
      type: RISK_DETAILS,
      payload: riskList.data,
    });
  }
};

export const downloadDDSPdf = (fedsaId: any) => async (dispatch) => {
  let url = `${appConfig.baseUrl}FEDSAPDFDetails/${fedsaId}`;
  const options = {
    url,
    method: 'GET',
    dispatch: null,
  };
  let pdfData = await callAPI(options);
  if (pdfData) {
    let byte = pdfData.data.fileBytes;
    if (byte) {
      downloadPdfFile(byte, pdfData);
    }
  }
};

export const downloadDDSExcel = (fedsaId: any) => async (dispatch) => {
  let url = `${appConfig.baseUrl}GLAResult/${fedsaId}`;
  const options = {
    url,
    method: 'GET',
    dispatch: null,
  };
  let excelData = await callAPI(options);
  if (excelData) {
    let byte = excelData.data.fileBytes;
    if (byte) {
      downloadExeclFile(byte, excelData);
    }
  }
};
