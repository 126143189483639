import Text from '@ingka/text';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import { Stack, Page } from 'components/layout';
import React, { ChangeEvent, useState, useEffect } from 'react';
import FormField from '@ingka/form-field';
import ManualEntry from 'pages/ManualEntry/manualEntry';
import Button from '@ingka/button';
import styled from 'styled-components';
import { DetailPage } from 'pages/Detail/Detail';
import { useLocation, useNavigate } from 'react-router-dom';
import documentAdd from '@ingka/ssr-icon/paths/document-add';
import Pill from '@ingka/pill';
import { convertFileToBase64 } from 'utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import { fileUpload, resetFileUpload } from 'Action/fileUploadAction';
import Toast from '@ingka/toast';
import './fileUpload.scss';
import InlineMessage from '@ingka/inline-message';

const FileUploadTemplate = styled.div`
  display: flex;
  flex-direction: column;
  // width: min-content;
`;
const ButtonDiv = styled.div`
  margin-left: 40px;
`;

export const Fedsa = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [byteStreamData, setByteStreamData] = useState<any>();
  const [toastOpen, setToastOpen] = useState<any>(false);
  const [hasNavigated, setHasNavigated] = useState(false);
  const [activeTab, setActiveTab] = useState('tab_1');
  const [toastMessage, setToastMessage] = useState('');
  const [inlineMessage, setInlineMessage] = useState('');
  const [inlineMessageVisible, setInlineMessageVisible] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { fileUploadData, fileUploadStatus, errorMessage } = useSelector(
    (state: RootState) => state.fileUploadDetails
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/fileupload') {
      setHasNavigated(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (toastOpen && !hasNavigated && fileUploadData?.supplierSuccessResponse) {
      setTimeout(() => {
        navigate('/approval');
        setHasNavigated(true);
        setTimeout(() => {
          setHasNavigated(false);
          setToastOpen(false);
        }, 6000);
      }, 6000);
    }
  }, [toastOpen, hasNavigated, navigate]);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(resetFileUpload());
    if (event.target.files) {
      const file = event.target.files[0];
      const fileExtension = file?.name.slice(file.name.lastIndexOf('.') + 1) || '';
      setSelectedFile(event.target.files[0]);
      const byteStream = await convertFileToBase64(file);
      setByteStreamData(byteStream);
      setByteStreamData({
        fileBytes: byteStream,
        fileType: fileExtension,
      });
      // event.target.value = null;
    }
  };

  const handleJsonTemplate = () => {
    const filePath = '/new 1.json';
    const a = document.createElement('a');
    a.href = filePath;
    a.download = 'new 1.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleSubmit = async () => {
    setToastOpen(false);
    setHasNavigated(false);
    try {
      const response = await dispatch(fileUpload(byteStreamData));
      if (response && 'status' in response && 'data' in response) {
        const { status, data } = response;

        if ((status === 200 || status === 201) && data.supplierSuccessResponse) {
          setToastMessage(
            `Success: successfully submitted. FRN ID: ${data.supplierSuccessResponse.frnId}`
          );
        } else {
          setToastMessage(
            `Error: ${data?.supplierErrorResponse?.errorMessage || 'File upload failed'}`
          );
          dispatch(resetFileUpload());
        }
      } else {
        setToastMessage('Error: File upload failed');
        dispatch(resetFileUpload());
      }
    } catch (error) {
      setToastMessage('Error: Network or server error');
      dispatch(resetFileUpload());
    }
    setToastOpen(true);
  };

  const onClose = () => {
    setToastOpen(false);
    if (toastMessage.startsWith('Error:')) {
      window.location.reload();
    }
  };
  const supplierDetails = (value: any) => {
    console.log(value, 'supplierDetails');
  };

  const tabs = [
    <Tab key="tab-1" text={'File Upload'} tabPanelId="tab_1" />,
    <Tab key="tab-2" text={'Manual Entry'} tabPanelId="tab_2" />,
  ];

  const tabPanels = [
    <TabPanel key="tab-1" tabPanelId="tab_1">
      <FormField>
        <input type="file" onChange={handleFileChange} accept=".json" />
      </FormField>
      <FileUploadTemplate style={{ width: 200 }}>
        <Pill
          iconPosition="trailing"
          size="small"
          label="Json file template"
          onClick={handleJsonTemplate}
          ssrIcon={documentAdd}
          style={{ marginBottom: '10px', borderRadius: '0px' }}
        />
      </FileUploadTemplate>
      <ButtonDiv>
        {selectedFile ? (
          <Button
            iconPosition="leading"
            key="submit"
            size="small"
            text="Submit"
            type="emphasised"
            onClick={handleSubmit}
          ></Button>
        ) : (
          <Button
            iconPosition="leading"
            key="submit"
            size="small"
            text="Submit"
            type="emphasised"
            disabled
          ></Button>
        )}
      </ButtonDiv>
    </TabPanel>,
    <TabPanel key="tab-2" tabPanelId="tab_2">
      <>
        <ManualEntry supplierDetails={supplierDetails} />
      </>
    </TabPanel>,
  ];

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    if (tabId === 'tab_2') {
      window.history.pushState({}, '', '/manualentry');
    } else {
      window.history.pushState({}, '', '/fileupload');
    }
  };
  const handleCopy = () => {
    if (fileUploadData?.supplierSuccessResponse?.frnId) {
      navigator.clipboard
        .writeText(fileUploadData.supplierSuccessResponse.frnId)
        .then(() => {
          setInlineMessage('FRN ID copied to clipboard');
          setInlineMessageVisible(true);
        })
        .catch((err) => {
          setInlineMessage('Failed to copy FRN ID');
          setInlineMessageVisible(true);
        });
    }
  };

  return (
    <>
      {location.pathname === '/approval' ? (
        <DetailPage />
      ) : (
        <Page className="file-upload-container" style={{ marginLeft: 20, maxWidth: 'fit-content' }}>
          <Tabs
            tabs={tabs}
            tabPanels={tabPanels}
            defaultActiveTab={activeTab}
            ariaLabel="Example Tabs Label"
            onTabChanged={handleTabChange}
          />
        </Page>
      )}
      {toastOpen && (
        <Toast
          className="toast-upload"
          actionButtonText={fileUploadData?.supplierSuccessResponse ? 'Copy' : null}
          isOpen={toastOpen}
          actionClick={() => {
            handleCopy();
          }}
          onCloseRequest={onClose}
          ariaLabelCloseBtn="Dismiss notification"
          text={<p>{toastMessage}</p>}
          key={fileUploadData?.supplierSuccessResponse ? 'success' : 'error'}
        />
      )}
      {inlineMessageVisible && (
        <InlineMessage
          body={inlineMessage}
          link=""
          linkLabel=""
          title="Notification"
          variant="positive"
        />
      )}
    </>
  );
};
