import { getRiskDetails } from 'Action/statusDetailsAction';
import DetailsLayout from 'components/SharedLayout/DetailsLayout/detailsLayout';
import TableLayout from 'components/SharedLayout/TableLayout/tableLayout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import { getFEDSAReferenceNumber } from 'utils/common';
const InvalidRecords = (props: any) => {
  const { queries, tabChange, handleTabChange, setQuery } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { invalidList, riskDetailList } = useSelector((state: RootState) => state.statusDetails);
  const [invalidListData, setInvalidListData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [showDetailsData, setShowDetailsData] = useState(false);
  const [riskDetailsById, setRiskDetailsById] = useState<any>([]);
  const [fedsaReference, setFEDSAReference] = useState<any>();
  const [ddsReference, setDDSReference] = useState<any>();
  const [comment, setComment] = useState('');
  const [craStatus, setCraStatus] = useState(true);
  const [glaStatus, setGlaStatus] = useState(true);
  const [acceptRiskChecked, setAcceptRiskChecked] = useState(false);
  const mapping = {
    isEntryFromAPI: 'Source',
    supplierName: 'Supplier Name',
    articles: 'Supplier Article Number / GTIN',
    goodsDescription: 'Trade Item Description',
    fedsa_RefNum: 'Ref_Num',
    receivedOn: 'DDS Received On',
    fedsaStatus: 'Current status',
    pdf: 'PDF',
  };
  const riskDetailMapping = {
    commodities: 'Commodities',
    country: 'Country',
    productionPlaceCount: 'Production Place',
    cra: 'CRA',
    gla: 'GLA',
  };
  useEffect(() => {
    if (queries?.length === 0) {
      let items = mappedItems(invalidList, mapping);
      setInvalidListData(items);
    } else {
      let items = mappedItems(filteredData, mapping);
      setInvalidListData(items);
    }
  }, [filteredData, invalidList, queries]);

  useEffect(() => {
    if (invalidList) {
      const filtered = invalidList.filter(
        (item) =>
          item.supplierName?.toLowerCase().includes(queries?.toLowerCase()) ||
          item.articles?.toLowerCase().includes(queries?.toLowerCase()) ||
          item.fedsa_RefNum?.toLowerCase().includes(queries?.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [queries, invalidList]);
  useEffect(() => {
    let items = mappedItems(riskDetailList, riskDetailMapping);
    setRiskDetailsById(items);
    if (riskDetailList?.length > 0) {
      const craPassed = riskDetailList?.every((item) => item?.cra?.isPassed);
      const glaPassed = riskDetailList?.every((item) => item?.gla?.isPassed);
      setCraStatus(craPassed);
      setGlaStatus(glaPassed);
    }
  }, [riskDetailList]);

  const mappedItems = (itemList, mappingKey) => {
    return itemList?.map((item) => {
      return Object.keys(mappingKey).reduce((acc, key) => {
        acc[mappingKey[key]] = item[key] !== undefined ? item[key] : null; // Set to null if the key does not exist
        return acc;
      }, {});
    });
  };
  const resetAcceptRiskChecked = () => {
    setAcceptRiskChecked(false);
  };

  const onShowDetails = (value, fedsa_RefNum) => {
    if (fedsa_RefNum) {
      setFEDSAReference(fedsa_RefNum);
      const selectedRow = getFEDSAReferenceNumber(invalidList, fedsa_RefNum);
      setDDSReference(selectedRow.dds_RefNum);
      dispatch(getRiskDetails(selectedRow.fedsa_RefNum));
    }
    setShowDetailsData(value);
  };
  const onSearch = (searchQuery) => {
    setQuery(searchQuery);
  };

  return (
    <div className="details-container">
      <div className="table-container">
        <div className="table-list-view">
          {invalidList && invalidListData?.length > 0 && (
            <TableLayout
              items={invalidListData}
              isLeftSide={true}
              pendingApprovalData={filteredData}
              tabChange={tabChange}
              onSearch={onSearch}
              acceptRiskChecked={acceptRiskChecked}
              setAcceptRiskChecked={setAcceptRiskChecked}
              resetAcceptRiskChecked={resetAcceptRiskChecked}
              onShowDetails={onShowDetails}
            />
          )}
        </div>
      </div>
      {showDetailsData && riskDetailList?.length > 0 && riskDetailsById?.length > 0 && (
        <div className="detail-list">
          <DetailsLayout
            data={riskDetailsById}
            isCheckBoxDisable={true}
            isTextAreaDisbale={true}
            isDropdown={false}
            fedsaReference={fedsaReference}
            ddsReference={ddsReference}
            pendingApprovalData={filteredData}
            comment={comment}
            setComment={setComment}
          />
        </div>
      )}
    </div>
  );
};
export default InvalidRecords;
