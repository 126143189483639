import DetailsLayout from 'components/SharedLayout/DetailsLayout/detailsLayout';
import TableLayout from 'components/SharedLayout/TableLayout/tableLayout';
import React, { useState, useEffect, FC } from 'react';
import { getRiskDetails } from 'Action/statusDetailsAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import { getFEDSAReferenceNumber } from 'utils/common';

interface SubmitRegisteryProps {
  queries: string;
  setQuery: (query: string) => void;
  tabChange: (tab: any) => void;
  items: any[];
  handleTabChange: (tabChange: string) => void;
}

const SubmitRegistery: FC<SubmitRegisteryProps> = ({
  queries,
  setQuery,
  tabChange,
  items,
  handleTabChange,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { pendingApprovalList, riskDetailList, submittedToEuList } = useSelector(
    (state: RootState) => state.statusDetails
  );
  const [fedsaReference, setFEDSAReference] = useState<any>();
  const [ddsReference, setDDSReference] = useState<any>();
  const [showDetailsData, setShowDetailsData] = useState(false);
  const [riskDetailsById, setRiskDetailsById] = useState<any>([]);
  const [comment, setComment] = useState('');
  const [acceptRiskChecked, setAcceptRiskChecked] = useState(false);
  const [autoApprove, setAutoApprove] = useState(false);
  const [SubmitListData, setSubmitListData] = useState<any>([]);
  const [filteredSubmitData, setFilteredSubmitData] = useState<any>([]);

  const mapping = {
    isEntryFromAPI: 'Source',
    supplierName: 'Supplier Name',
    fedsa_RefNum: 'Ref_Num',
    dds_RefNum: 'DDS Reference Number',
    articles: 'Supplier Articles Number / GTIN',
    goodsDescription: 'Trade Item Description',
    submittedOn: 'Date of Submission',
    ddsStatus: 'Current status',
    activityType: 'Activity Type',
    pdf: 'PDF',
  };
  const riskDetailMapping = {
    commodities: 'Commodities',
    country: 'Country',
    productionPlaceCount: 'Production Place',
    cra: 'CRA',
    gla: 'GLA',
  };
  {
    useEffect(() => {
      if (queries.length === 0) {
        let items = mappedItems(submittedToEuList, mapping);
        setSubmitListData(items);
      } else {
        let items = mappedItems(filteredSubmitData, mapping);
        setSubmitListData(items);
      }
    }, [filteredSubmitData, queries, submittedToEuList]);
    const mappedItems = (itemList, mappingKey) => {
      return itemList?.map((item) => {
        return Object.keys(mappingKey).reduce((acc, key) => {
          acc[mappingKey[key]] = item[key] !== undefined ? item[key] : null; // Set to null if the key does not exist
          return acc;
        }, {});
      });
    };

    useEffect(() => {
      let items = mappedItems(riskDetailList, riskDetailMapping);
      setRiskDetailsById(items);
    }, [riskDetailList]);
    const onShowDetails = (value, Ref_Num) => {
      if (Ref_Num) {
        setFEDSAReference(Ref_Num);
        const selectedRow = getFEDSAReferenceNumber(submittedToEuList, Ref_Num);
        setDDSReference(selectedRow?.dds_RefNum);
        dispatch(getRiskDetails(selectedRow.fedsa_RefNum));
      }
      setShowDetailsData(value);
    };

    useEffect(() => {
      if (submittedToEuList) {
        const filtered = submittedToEuList.filter(
          (item) =>
            item.supplierName?.toLowerCase().includes(queries.toLowerCase()) ||
            item.articles?.toLowerCase().includes(queries.toLowerCase()) ||
            item.fedsa_RefNum?.toLowerCase().includes(queries.toLowerCase()) ||
            item.dds_RefNum?.toLowerCase().includes(queries.toLowerCase())
        );

        setFilteredSubmitData(filtered);
      }
    }, [queries, submittedToEuList]);

    const withDraw = (e: any, button: any, index: any) => {
      console.log(e, button, index);
    };
    const resetAcceptRiskChecked = () => {
      setAcceptRiskChecked(false);
    };

    const resetAutoApprove = () => {
      setAutoApprove(false);
    };
    const buttonData = [
      { label: 'Withdraw DDS', onClick: withDraw },
      // Add more button configurations as needed
    ];
    const onSearch = (searchQuery) => {
      setQuery(searchQuery);
    };
    return (
      <div className="details-container">
        <div className="table-container">
          <div className="table-list-view">
            {submittedToEuList && SubmitListData?.length > 0 && (
              <TableLayout
                items={SubmitListData}
                isLeftSide={true}
                onShowDetails={onShowDetails}
                pendingApprovalData={filteredSubmitData}
                acceptRiskChecked={acceptRiskChecked}
                setAcceptRiskChecked={setAcceptRiskChecked}
                resetAcceptRiskChecked={resetAcceptRiskChecked}
                resetAutoApprove={resetAutoApprove}
                autoApprove={autoApprove}
                setAutoApprove={setAutoApprove}
                setComment={setComment}
                onSearch={onSearch}
                tabChange={tabChange}
              />
            )}
          </div>
        </div>
        {showDetailsData && riskDetailList?.length > 0 && riskDetailsById?.length > 0 && (
          <div className="detail-list">
            <DetailsLayout
              data={riskDetailsById}
              buttonData={buttonData}
              isCheckBoxDisable={true}
              isTextAreaDisbale={true}
              fedsaReference={fedsaReference}
              ddsReference={ddsReference}
              isDropdown={false}
              comment={comment}
              setComment={setComment}
            />
          </div>
        )}
      </div>
    );
  }
};

export default SubmitRegistery;
