import { Page, PageFEDSA } from 'components/layout';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import Text from '@ingka/text';
import React, { useState, useEffect, useRef } from 'react';
import Search from '@ingka/search';
import styled from 'styled-components';
import { contentDelimiterStyles } from 'components/layout';
import { DataTable } from 'components/common/DataTable';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'Store/store';
import TableLayout from 'components/SharedLayout/TableLayout/tableLayout';
import PendingApproval from 'pages/PendingApproval/pendingApproval';
import SaveForLater from 'pages/SaveForLater/saveForLater';
import SubmitRegistery from 'pages/SubmitRegistery/submitRegistery';
import Archived from 'pages/Archived/archived';
import Button from '@ingka/button';
import { useNavigate } from 'react-router-dom';
import './details.scss';
import GlobalSearch from 'components/GlobalSearch/globalSearch';
import { getStatusList, getRiskDetails } from 'Action/statusDetailsAction';
import InvalidRecords from 'pages/InvalidRecords/invalidRecords';
import { set } from 'react-hook-form';

const FileUpload = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10%;
`;

export const DetailPage = (props: any) => {
  const { tabChange, items, setQuery } = props;
  const [pendingApprovalQuery, setPendingApprovalQuery] = useState('');
  const [saveForLaterQuery, setSaveForLaterQuery] = useState('');
  const [submitRegistryQuery, setSubmitRegistryQuery] = useState('');
  const [archivedQuery, setArchivedQuery] = useState('');
  const [invalidatedQuery, setInvalidatedQuery] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const [selectedStatus, setSelectedStatus] = useState('Pending');
  const [tabChanged, setTabChanged] = useState(false);
  const submittedToEUTabRef = useRef<HTMLButtonElement>(null);

  const handleTabChange = (tabChange) => {
    setSelectedStatus(tabChange);

    if (tabChange === 'Pending') {
      setPendingApprovalQuery('');
      handlePendingApprovalSearch('');
      setTabChanged(true);
    } else if (tabChange === 'SaveForLater') {
      setSaveForLaterQuery('');
      handleSaveForLaterSearch('');
      setTabChanged(true);
    } else if (tabChange === 'SubmittedForEUDR') {
      setSubmitRegistryQuery('');
      handleSubmitRegistrySearch('');
      setTabChanged(true);
    } else if (tabChange === 'Archived') {
      setTabChanged(true);
      setArchivedQuery('');
      handleArchivedSearch('');
    } else if (tabChange === 'Invalidate') {
      setTabChanged(true);
      setInvalidatedQuery('');
      handleInvalidatedSearch('');
    }
  };
  const handlePendingApprovalSearch = (queries) => {
    setPendingApprovalQuery(queries);
  };

  const handleSaveForLaterSearch = (queries) => {
    setSaveForLaterQuery(queries);
  };
  const handleSubmitRegistrySearch = (queries) => {
    setSubmitRegistryQuery(queries);
  };
  const handleArchivedSearch = (queries) => {
    setArchivedQuery(queries);
  };
  const handleInvalidatedSearch = (queries) => {
    setInvalidatedQuery(queries);
  };
  useEffect(() => {
    if (selectedStatus === 'Pending') {
      dispatch(getStatusList(selectedStatus));
    } else if (selectedStatus === 'SaveForLater') {
      dispatch(getStatusList(selectedStatus));
    } else if (selectedStatus === 'SubmittedForEUDR') {
      dispatch(getStatusList(selectedStatus));
    } else if (selectedStatus === 'Archived') {
      dispatch(getStatusList(selectedStatus));
    } else if (selectedStatus === 'Invalidate') {
      dispatch(getStatusList(selectedStatus));
    }
  }, [selectedStatus]);

  useSelector((state: RootState) => state.fileUploadDetails);
  useSelector((state: RootState) => state.error);
  const navigate = useNavigate();

  const tabContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
  };

  const tabs = [
    <div key="detailkey" style={tabContainerStyle}>
      <Tab
        key="tab-3"
        text={'Pending Approval'}
        tabPanelId="tab_3"
        onClick={() => handleTabChange('Pending')}
      />
      <Tab
        key="tab-4"
        text={'Save for Later'}
        tabPanelId="tab_4"
        onClick={() => handleTabChange('SaveForLater')}
      />
      <Tab
        key="tab-5"
        text={'Submitted to EU Registry'}
        tabPanelId="tab_5"
        onClick={() => handleTabChange('SubmittedForEUDR')}
        ref={submittedToEUTabRef}
      />
      <Tab
        key="tab-6"
        text={'Archived'}
        tabPanelId="tab_6"
        onClick={() => handleTabChange('Archived')}
      />
      <Tab
        key="tab-7"
        text={'Invalid'}
        tabPanelId="tab_7"
        onClick={() => handleTabChange('Invalidate')}
      />
    </div>,
  ];

  const onClick = () => {
    navigate('/fileupload');
  };
  const tabPanels = [
    <TabPanel key="pendingApprovalPanel" tabPanelId="tab_3" className="pendingApprovalPanel">
      <PendingApproval
        queries={pendingApprovalQuery}
        setQuery={setPendingApprovalQuery}
        tabChange={handleTabChange}
        handleTabChange={handleTabChange}
        submittedToEUTabRef={submittedToEUTabRef}
        items={items}
      />
    </TabPanel>,
    <TabPanel key="saveForLaterPanel" tabPanelId="tab_4">
      <SaveForLater
        queries={saveForLaterQuery}
        setQuery={setSaveForLaterQuery}
        tabChange={tabChange}
        submittedToEUTabRef={submittedToEUTabRef}
        items={items}
        handleTabChange={handleTabChange}
      />
    </TabPanel>,
    <TabPanel key="submitRegisteryPanel" tabPanelId="tab_5">
      <SubmitRegistery
        queries={submitRegistryQuery}
        setQuery={setSubmitRegistryQuery}
        tabChange={tabChange}
        items={items}
        handleTabChange={handleTabChange}
      />
    </TabPanel>,
    <TabPanel key="archivedPanel" tabPanelId="tab_6">
      <Archived
        queries={archivedQuery}
        setQuery={setArchivedQuery}
        tabChange={tabChange}
        items={items}
        handleTabChange={handleTabChange}
      />
    </TabPanel>,
    <TabPanel key="invalidPanel" tabPanelId="tab_7">
      <InvalidRecords
        queries={invalidatedQuery}
        setQuery={setInvalidatedQuery}
        tabChange={tabChange}
        items={items}
        handleTabChange={handleTabChange}
      />
    </TabPanel>,
  ];
  const getSearchHandler = () => {
    switch (selectedStatus) {
      case 'Pending':
        return handlePendingApprovalSearch;
      case 'SaveForLater':
        return handleSaveForLaterSearch;
      case 'SubmittedForEUDR':
        return handleSubmitRegistrySearch;
      case 'Archived':
        return handleArchivedSearch;
      case 'Invalidate':
        return handleInvalidatedSearch;
      default:
        return () => {};
    }
  };
  return (
    <PageFEDSA>
      <div className="tab-header">
        <div className="pending-container">
          <FileUpload style={{ display: 'flex', justifyContent: 'space-between', padding: 16 }}>
            <GlobalSearch
              onSearch={getSearchHandler()}
              tabChange={handleTabChange}
              tabChanged={tabChanged}
              setTabChanged={setTabChanged}
            />
            <Button
              className="UploadSubmission"
              text="Upload Submission"
              size="xsmall"
              onClick={onClick}
            />
          </FileUpload>

          <Tabs
            tabs={tabs}
            tabPanels={tabPanels}
            defaultActiveTab="tab_3"
            ariaLabel="Example Tabs Label"
            className="parent-tab"
          />
        </div>
      </div>
    </PageFEDSA>
  );
};
